import React from "react";
import DateSearchBar from "../DateSearchBar";
import SubHorizontalMenu from "./SubHorizontalMenu";
import EsportsBetHistoryTable from "../tables/EsportsBetHistoryTable";
import AllIcon from "../../../images/myPage/betHistory/all.png";
import bet1 from "../../../images/myPage/betHistory/esports/bet1.png";
import bet2 from "../../../images/myPage/betHistory/esports/bet2.png";

const subTabsArray = [
  { text: "전체", icon: AllIcon, id: 0 },
  { text: "e-스포츠", icon: bet1, id: 1 },
  { text: "비터 e-스포츠", icon: bet2, id: 2 },
];
const ESportsBetHistory = ({
  showSub = true,
  isPopup = false,
  isState,
  setState,
}) => {
  return (
    <div className="w-full">
      {showSub === true && (
        <div
          className="mt-10px w-full px-3px py-3px rounded-6px"
          style={{
            height: "87px",
            background: "rgba(52, 34, 103, 0.6)",
          }}
        >
          <SubHorizontalMenu
            itemsArray={subTabsArray}
            isState={isState}
            setState={setState}
          />
        </div>
      )}
      <DateSearchBar isLeagueSearch={false} isPopup={isPopup} />

      <div className="w-full h-full mt-10px">
        <EsportsBetHistoryTable />
      </div>
    </div>
  );
};

export default ESportsBetHistory;
